import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'normalize.css'
import '@/utils/request'
import '@/components/Global'
import * as echarts from 'echarts'
import dayjs from 'dayjs'
import utils from '@/utils'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'swiper/swiper-bundle.css'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import { Swiper as SwiperClass, Autoplay } from 'swiper/swiper.esm'
import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter'
import countTo from 'vue-count-to'
import ICountUp from 'vue-countup-v2'
import 'viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'
import Video from 'video.js'

SwiperClass.use([Autoplay])
Vue.use(getAwesomeSwiper(SwiperClass))
Vue.use(countTo)
Vue.use(utils)
Vue.use(ElementUI)
Vue.use(VueAwesomeSwiper)
Vue.use(Viewer)
Viewer.setDefaults({
  Options: {
    inline: true,
    button: true,
    navbar: true,
    title: false,
    toolbar: true,
    tooltip: true,
    movable: true,
    zoomable: true,
    rotatable: true,
    scalable: true,
    transition: true,
    fullscreen: true,
    keyboard: true,
    url: 'data-source'
  }
})

Vue.prototype.$echarts = echarts
Vue.prototype.$eventBus = new Vue()
Vue.prototype.dayjs = dayjs
Vue.prototype.$video = Video
Vue.component('countTo', countTo)
Vue.component('ICountUp', ICountUp)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
