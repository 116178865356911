export default {
  data() {
    return {
      timeTicket: null
    }
  },
  methods: {
    _beforeDestroy() {
      this.timeTicket && clearInterval(this.timeTicket)
    },
    // 启动动画时使用
    tooltipAnimate(chart, length) {
      // 清除上一次动画
      this.timeTicket && clearInterval(this.timeTicket)
      let count = 0
      // 启动动画
      this._action(chart, count, length)
      chart && // 移除动画
        chart.on('mouseover', params => {
          this._cleanAction(chart, params)
        })
      // 重写启动动画
      chart &&
        chart.on('mouseout', () => {
          this._action(chart, count, length)
        })
    },
    // tooltip动画action
    _action(chart, count, length) {
      this.timeTicket && clearInterval(this.timeTicket)
      this.timeTicket = setInterval(() => {
        if (!chart) {
          clearInterval(this.timeTicket)
          return
        }
        chart &&
          chart.dispatchAction({
            type: 'downplay',
            seriesIndex: 0
          })
        chart &&
          chart.dispatchAction({
            type: 'highlight',
            seriesIndex: 0,
            dataIndex: count % length
          })
        chart &&
          chart.dispatchAction({
            type: 'showTip',
            seriesIndex: 0,
            dataIndex: count % length
          })
        count++
      }, 1000 * 3)
    },
    _cleanAction(chart, params) {
      this.timeTicket && clearInterval(this.timeTicket)
      if (!chart) {
        clearInterval(this.timeTicket)
        return
      }
      chart &&
        chart.dispatchAction({
          type: 'downplay',
          seriesIndex: 0
        })
      chart &&
        chart.dispatchAction({
          type: 'highlight',
          seriesIndex: 0,
          dataIndex: params.dataIndex
        })
      chart &&
        chart.dispatchAction({
          type: 'showTip',
          seriesIndex: 0,
          dataIndex: params.dataIndex
        })
    }
  }
}
