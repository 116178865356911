import { _axios } from '@/utils/request'

const Api = {
  cs_total_top_three: '/stat/passengerFlow/comprehensiveStatistics',
  cs_total_car: '/stat/parkingAnalysis/realTimeNum',
  cs_total_store: '/shop/store/findStoreNum',
  cs_total_alarm_event: '/stat/alarmRecord/alarmNum',
  cs_l1: '/stat/passengerFlow/getPassengerFlowData',
  cs_l2: '/stat/parkingAnalysis/parkingFlowChart',
  cs_l3: '/stat/consumption/getConsumptionTrendData',
  cs_r1: '/stat/alarmRecord/alarmListByComprehensive',
  cs_alarm_detail: '/stat/alarmRecord/getDetail',
  cs_r2_total: '/stat/sentiment/emotionHistory',
  cs_r2_l: '/stat/sentiment/emotionProportion',
  cs_emo_list: '/stat/sentiment/sentimentDataStreams',
  cs_r2_r: '/stat/sentiment/satisfactionAnalysis',
  cs_r3_total: '/cmsv2/stat/statStoreEnteringPlatform',
  cs_r3_bar: '/cmsv2/stat/storeEnterBusinessNumber',
  cs_r3_line: '/stat/store/findStoreNewAddNumForLastYear'
}

/**
 * 综合态势-total 实时客流、今日累计客流、今日交易额
 * @param params
 */
export const cs_total_top_three = async params => {
  return _axios.post(Api.cs_total_top_three, params)
}
/**
 * 综合态势-total 实时车流
 * @param params
 */
export const cs_total_car = async params => {
  return _axios.get(Api.cs_total_car, params)
}
/**
 * 综合态势-total 商家数量
 * @param params
 */
export const cs_total_store = async params => {
  return _axios.get(Api.cs_total_store, params)
}
/**
 * 综合态势-total 预警事件
 * @param params
 */
export const cs_total_alarm_event = async params => {
  return _axios.get(Api.cs_total_alarm_event, params)
}

export const cs_l1 = async params => {
  return _axios.post(Api.cs_l1, params)
}
export const cs_l2 = async params => {
  return _axios.post(Api.cs_l2, params)
}
export const cs_l3 = async params => {
  return _axios.post(Api.cs_l3, params)
}
export const cs_r1 = async params => {
  return _axios.get(Api.cs_r1, params)
}
export const cs_alarm_detail = async params => {
  return _axios.get(Api.cs_alarm_detail, params)
}
export const cs_r2_total = async params => {
  return _axios.post(Api.cs_r2_total, params)
}
export const cs_r2_l = async params => {
  return _axios.post(Api.cs_r2_l, params)
}
export const cs_emo_list = async params => {
  return _axios.post(Api.cs_emo_list, params)
}
export const cs_r2_r = async params => {
  return _axios.post(Api.cs_r2_r, params)
}
export const cs_r3_total = async params => {
  return _axios.post(Api.cs_r3_total, params)
}
export const cs_r3_bar = async params => {
  return _axios.post(Api.cs_r3_bar, params)
}
export const cs_r3_line = async params => {
  return _axios.post(Api.cs_r3_line, params)
}
