<template>
  <div class="ek-segements">
    <div
      class="item"
      :class="currIndex == i ? 'active' : ''"
      v-for="(item, i) in list"
      :key="i"
      @click="handleItem(i)"
    >
      <span>{{ item }}</span>
    </div>
  </div>
</template>
  
  <script>
export default {
  data() {
    return {
      currIndex: 0,
    }
  },
  watch: {
    index: {
      handler(val) {
        if (val) {
          this.currIndex = val
        }
      },
      immediate: true,
    },
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    handleItem(i) {
      this.currIndex = i
      this.$emit('click', i)
    },
  },
}
</script>
  
  <style scoped lang="scss">
.ek-segements {
  @include flex();
  align-items: center;
  height: 34px;
  .item {
    height: 100%;
    @include flex();
    border-radius: 2px;
    align-items: center;
    background: rgba(53, 255, 210, 0.12);
    padding: 0 15px;
    border: 1px solid #35ffd2;
    font-size: 14px;
    margin-right: 7px;
    cursor: pointer;
  }
  .active {
    color: #35ffd2;
    font-weight: bold;
  }
}
</style>
  