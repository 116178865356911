<template>
  <div id="app">
    <welcome v-if="curIndex == 0" />
    <ek-video v-if="curIndex == 1" />
    <div
      class="home"
      v-if="token"
      :style="{ 'z-index': curIndex != 2 ? '-1' : '2' }"
    >
      <router-view />
    </div>
  </div>
</template>

<script>
import { fetchToken } from '@/api/login'
import { mapGetters } from 'vuex'
import ekVideo from '@/views/Video.vue'
import welcome from '@/views/Welcome.vue'

export default {
  data() {
    return {
      token: '',
      routes: [
        { index: 0, name: 'welcome' },
        { index: 1, name: 'video' },
        { index: 2, name: 'ov' }
      ],
      curIndex: 2
    }
  },
  components: {
    welcome,
    ekVideo
  },
  computed: {
    ...mapGetters(['headerType', 'menuType'])
  },
  watch: {
    headerType: {
      handler(value) {
        this.$router.push({ path: `/${value}` }).catch(() => {})
      },
      immediate: true
    },
    menuType: {
      handler(value) {
        this.$router
          .push({ path: `/${this.headerType}/${value}` })
          .catch(() => {})
      },
      immediate: false
    }
  },
  mounted() {
    if (!this.token) {
      fetchToken().then(token => {
        this.token = token
      })
      setInterval(() => {
        fetchToken()
      }, 1000 * 60 * 60 * 1.5)
    }
    this.$eventBus.$off('push-router-index')
    this.$eventBus.$on('push-router-index', index => {
      this.curIndex = index
    })
  },
  methods: {
    // pushRouter(type) {
    //   this.$router.push({ path: `/${type}` }).catch(() => {})
    //   this.$store.dispatch('setHeaderType', type)
    // }
  },
  created: function() {
    var _this = this
    document.onkeydown = function(event) {
      let key = event.key
      switch (key) {
        case 'PageUp':
          {
            if (_this.curIndex <= 0) return
            _this.curIndex -= 1
            // _this.pushRouter(_this.routes[_this.curIndex].name)
          }
          break
        case 'PageDown':
          {
            if (_this.curIndex >= 2) return
            _this.curIndex += 1
            // _this.pushRouter(_this.routes[_this.curIndex].name)
          }
          break
        case 'ArrowUp':
          {
            if (_this.curIndex <= 0) return
            _this.curIndex -= 1
            // _this.pushRouter(_this.routes[_this.curIndex].name)
          }
          break
        case 'ArrowDown':
          {
            if (_this.curIndex >= 2) return
            _this.curIndex += 1
            // _this.pushRouter(_this.routes[_this.curIndex].name)
          }
          break
      }
    }
  }
}
</script>

<style lang="scss">
.home {
  position: absolute;
}
</style>
