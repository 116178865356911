<template>
  <div class="empty">
    <div class="icon"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  methods: {}
}
</script>

<style scoped lang="scss">
.empty {
  z-index: 99;
  width: 100%;
  // height: 100%;
  height: calc(100% - 20px);
  padding: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .icon {
    flex: 1;
    height: 100%;
    background: url('/images/com/empty-icon.png') no-repeat;
    background-size: contain;
    background-position: 50% 50%;
  }
}
</style>
