let base = {}

base.install = function(Vue) {
  Vue.prototype.groupList = function groupList(array, subGroupLength) {
    let index = 0
    let newArray = []
    while (index < array.length) {
      newArray.push(array.slice(index, (index += subGroupLength)))
    }
    return newArray
  }

  // 数组元素随机
  Vue.prototype.getRandomArrayValue = function getRandomArrayValue(arr, num) {
    var sData = arr.slice(0),
      i = arr.length,
      min = i - num,
      item,
      index
    while (i-- > min) {
      index = Math.floor((i + 1) * Math.random())
      item = sData[index]
      sData[index] = sData[i]
      sData[i] = item
    }
    return sData.slice(min)
  }

  Vue.prototype.markerType = function markerType(type) {
    switch (type) {
      case '10':
        return 'wh'
      case '20':
        return 'lzh'
      case '30':
        return 'whdkd'
      case '40':
        return 'sy'
      case 'trash':
        return 'ljt'
      case 'toilet':
        return 'gc'
      case 'wifi':
        return 'wifi'
      case 'manhole':
        return 'jg'
      case 'lighting':
        return 'zndg'
      case 'column':
        return 'bjz'
      case 'se_camera':
        return 'afjk'
      case 'megaphone':
        return 'ks'
      case 'handheld':
        return 'db'
      case 'pf_camera':
        return 'kljk'
      case 'board':
        return 'gb'
      case 'gate':
        return 'bz'
      case 'parking':
        return 'tcc'
      case 'service_facilities':
        return 'fwss'
      case 'ee_camera':
        return 'yyjk'
      case 'xxgy':
        return '市民休闲公园'
      case 'tskt':
        return '特色商业客厅'
      case 'fhkt':
        return '复合商业客厅'
      case 'xxgc':
        return '生活休闲广场'
    }
  }

  Vue.prototype.dateType = function dateType(type) {
    switch (type) {
      case 0:
        return 'day'
      case 1:
        return 'month'
      case 2:
        return 'year'
    }
  }

  Vue.prototype.labelType = function labelType(type) {
    switch (type) {
      case 'online':
        return '用户上线'
      case 'trade':
        return '用户交易'
      case 'rate':
        return '在线点餐'
      case 'ordering':
        return '用户评价'
      case 'activity':
        return '参与活动'
    }
  }

  Vue.prototype.typeCode = function typeCode(type) {
    switch (type) {
      case 'signup_manage':
        return '活动'
      case 'gonglue':
        return '文章'
      case 'discover_content':
        return '文章'
      case 'bus_imformation':
        return '资讯'
      case '餐饮':
        return 'cy'
      case '婚庆':
        return 'hq'
      case '文娱':
        return 'wy'
      case '医疗':
        return 'yl'
      case '生活服务':
        return 'shfw'
      case '其他':
        return 'qt'
    }
  }

  Vue.prototype.dictionary = function dictionary(model, dicType) {
    const dictionaryList = this.$store.getters.dictionaryList(dicType)
    if (dictionaryList) {
      if (model instanceof Array) {
        const dictNameArr = dictionaryList
          .filter(item => model.includes(item.businId))
          .map(item => item.businName)
        return dictNameArr.join(' ' + ',' + ' ')
      } else {
        const dictionary = dictionaryList.find(item => item.businId === model)
        if (dictionary) {
          return dictionary.businName
        } else {
          return model
        }
      }
    } else {
      return model
    }
  }

  Vue.prototype.remReSize = function fontSize(val, initWidth = 3744) {
    const width = document.body.scrollWidth
    if (!width) return
    return val * (width / initWidth)
  }
}

export default base
