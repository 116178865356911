<template>
  <div class="ek-segements">
    <div
      class="item"
      :class="currIndex == i && defaultSelect ? 'active' : ''"
      v-for="(item, i) in list"
      :key="i"
      @click="handleItem(i)"
    >
      <span>{{ item }}</span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currIndex: 0
    }
  },
  watch: {
    index: {
      handler(val) {
        this.currIndex = val
      },
      immediate: true
    }
  },
  props: {
    list: {
      type: Array,
      default: () => ['日', '月', '年']
    },
    index: {
      type: Number,
      default: 0
    },
    defaultSelect: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    handleItem(i) {
      this.currIndex = i
      this.$emit('click', i)
    }
  }
}
</script>

<style scoped lang="scss">
.ek-segements {
  @include flex();
  align-items: center;
  height: 34px;
  border: 1px solid #ef9c00;
  border-radius: 6px;
  .item {
    height: 100%;
    @include flex();
    align-items: center;
    padding: 0 15px;
    border-right: 1px solid #ef9c00;
    font-size: 14px;
    cursor: pointer;
  }
  .item:last-child {
    border-right: none;
  }
  .active {
    color: #ef9c00;
    font-weight: bold;
  }
}
</style>
