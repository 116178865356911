import { _axios } from '@/utils/request'

export const fetchToken = async () => {
  const { resultCode, resultData } = await _axios.post('/auth', {
    username: 'ioctaidong',
    password: 'cAWB35q8wQN1vtlk'
  })

  if (resultData && resultCode == '200') {
    sessionStorage.setItem('token', resultData.token)
    return resultData.token
  }
}
