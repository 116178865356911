import { _axios } from '@/utils/request'

const Api = {
  ov_br_r2: '/cmsv2/stat/storeBusinessNumber',
  total_dev_online: '/stat/deviceInfo/OnlineDeviceNum',
  map_dev_marker: '/stat/deviceInfo/deviceListBySource',
  holiday: '/stat/holiday/getHolidayData',
  alarmList:'/stat/alarmRecord/queryListPage',
  changeLightingMode:'/stat/deviceInfo/changeLightingMode',
  queryLightLoopLocation:'/stat/deviceInfo/queryLightLoopLocation',
  lightLoopList:'/stat/deviceInfo/lightLoopList',
  controlDeviceSwitch:'/stat/deviceInfo/controlDeviceSwitch',
  deviceAlarmNum:'/stat/alarmRecord/deviceAlarmNum',
  parkAndPssengerFlowDetail:'/stat/deviceInfo/parkAndPssengerFlowDetail'
}

/**
 * 台东概况-业态分布
 * @param params
 */
export const ov_br_r2 = async params => {
  return _axios.get(Api.ov_br_r2, params)
}
/**
 * 智慧管理-在线设备数
 * @param params
 */
export const total_dev_online = async params => {
  return _axios.get(Api.total_dev_online, params)
}
/**
 * 智慧管理/综合态势-地图设备点位
 * @param params
 */
export const map_dev_marker = async params => {
  return _axios.post(Api.map_dev_marker, params)
}

/**
 * 节假日列表
 * @param params
 */
export const holiday = async params => {
  return _axios.post(Api.holiday, params)
}
/*智能管理 报警事件*/
export const alarmList = async params =>{
  return _axios.post(Api.alarmList, params)
}
export const changeLightingMode = async params =>{
  return _axios.post(Api.changeLightingMode, params)
}
export const queryLightLoop = async params =>{
  return _axios.get(Api.queryLightLoopLocation, params)
}
export const lightLoopList = async params =>{
  return _axios.post(Api.lightLoopList, params)
}
export const controlDeviceSwitch = async params =>{
  return _axios.post(Api.controlDeviceSwitch, params)
}
export const deviceAlarmNum = async params =>{
  return _axios.post(Api.deviceAlarmNum, params)
}
export const parkAndPssengerFlowDetail = async params =>{
  return _axios.get(Api.parkAndPssengerFlowDetail, {params:params})
}