<template>
  <div class="ek-chart"></div>
</template>
<script>
import animate from '@/utils/animate'

export default {
  data() {
    return {
      chart: null
    }
  },
  props: {
    options: {
      type: Object,
      default: () => ({})
    },
    animation: {
      type: Boolean,
      default: false
    },
    animationData: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    options: {
      handler(options) {
        this.chart.setOption(options, true)
        this.tooltipAnimate(this.chart, this.animationData.length)
        setTimeout(() => {
          this.chart.resize()
        }, 200)
      },
      deep: true
    }
  },
  mixins: [animate],
  mounted() {
    this.initChart()
    window.addEventListener('resize', () => {
      this.chart.resize()
    })
  },
  methods: {
    initChart() {
      this.chart = this.$echarts.init(this.$el)
      this.$emit('ekCharts', this.chart) // 把实例丢出来
      this.chart.setOption(this.options, true)

      setTimeout(() => {
        this.tooltipAnimate(this.chart, this.animationData.length)
      }, 2000)
    }
  },
  beforeDestroy() {
    this._beforeDestroy()
  }
}
</script>
