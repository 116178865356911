import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   redirect: '/welcome'
  // },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/Home'),
    children: [
      {
        path: '/ov',
        name: 'ov',
        component: () => import('@/views/ov'),
        redirect: '/ov/br',
        children: [
          {
            path: 'br',
            name: 'br',
            component: () => import('@/views/ov/br')
          },
          {
            path: 'bfp',
            name: 'bfp',
            component: () => import('@/views/ov/bfp')
          },
          {
            path: 'dh',
            name: 'dh',
            component: () => import('@/views/ov/dh')
          },
          {
            path: 'dc',
            name: 'dc',
            component: () => import('@/views/ov/dc')
          }
        ]
      },
      {
        path: '/iw',
        name: 'iw',
        component: () => import('@/views/iw')
      },
      {
        path: '/cs',
        name: 'cs',
        component: () => import('@/views/cs')
      },
      {
        path: '/do',
        name: 'operate',
        component: () => import('@/views/do')
      },
      {
        path: '/std',
        name: 'std',
        component: () => import('@/views/std')
      },
      {
        path: '/bdg',
        name: 'bdg',
        component: () => import('@/views/bdg'),
        redirect: '/bdg/jqdj',
        children: [
          {
            path: 'jqdj',
            name: 'jqdj',
            component: () => import('@/views/bdg/jqdj')
          },
          {
            path: 'jqfw',
            name: 'jqfw',
            component: () => import('@/views/bdg/jqfw')
          },
          {
            path: 'jqzl',
            name: 'jqzl',
            component: () => import('@/views/bdg/jqzl')
          }
        ]
      }
    ]
  }
  // {
  //   path: '/welcome',
  //   name: 'velcome',
  //   component: () => import('@/views/Welcome')
  // },
  // {
  //   path: '/video',
  //   name: 'video',
  //   component: () => import('@/views/Video')
  // }
]

const router = new VueRouter({
  routes
})

/* eslint-disable */
router.beforeEach((to, from, next) => {
  store.dispatch('setClearCancel')
  next()
})
export default router
