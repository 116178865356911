import { _axios } from '@/utils/request'

const Api = {
  l1_1: '/stat/user/findUserNumListForType',
  l1_2and3: '/stat/portraitbasic/getPortraitBasicData',
  l1_4: '/stat/portraitbasic/getSourceCityData',
  l1_5: '/stat/portraitbasic/getSourceCityRankData',
  l1_6and7: '/stat/portraitbasic/getTravelPreferencesData',

  l2_1: '/stat/consumption/getConsumptionTrendData',
  l2_2: '/stat/consumption/getConsumptionFenceProportionData',
  l2_3: '/stat/consumption/getConsumptionPreferencesData',
  l2_4: '/stat/consumption/getConsumptionMerchantTradeData',
  l2_5: '/stat/consumption/getConsumptionMerchantData',
  l2_6: '/stat/consumption/getConsumptionTimeperiodData',
  l2_7: '/stat/consumption/getConsumptionRangeData',

  r1_1: '/shop/signupAct/findStatistic',
  total_user: '/stat/user/findUserCountNum',
  total_transaction_user: '/shop/order/stat/findTransactionUserNum'
}

/**
 * 综合态势-total 实时车流
 * @param params
 */
export const cs_total_car = async params => {
  return _axios.get(Api.cs_total_car, params)
}
/**
 * 综合态势-total 商家数量
 * @param params
 */
export const cs_total_store = async params => {
  return _axios.get(Api.cs_total_store, params)
}
/**
 * 综合态势-total 预警事件
 * @param params
 */
export const cs_total_alarm_event = async params => {
  return _axios.get(Api.cs_total_alarm_event, params)
}

export const l1_1 = async params => {
  return _axios.post(Api.l1_1, params)
}
export const l1_2and3 = async params => {
  return _axios.post(Api.l1_2and3, params)
}
export const l1_4 = async params => {
  return _axios.post(Api.l1_4, params)
}
export const l1_5 = async params => {
  return _axios.post(Api.l1_5, params)
}
export const l1_6and7 = async params => {
  return _axios.post(Api.l1_6and7, params)
}

export const l2_1 = async params => {
  return _axios.post(Api.l2_1, params)
}
export const l2_2 = async params => {
  return _axios.post(Api.l2_2, params)
}
export const l2_3 = async params => {
  return _axios.post(Api.l2_3, params)
}
export const l2_4 = async params => {
  return _axios.post(Api.l2_4, params)
}
export const l2_5 = async params => {
  return _axios.post(Api.l2_5, params)
}
export const l2_6 = async params => {
  return _axios.post(Api.l2_6, params)
}
export const l2_7 = async params => {
  return _axios.post(Api.l2_7, params)
}

export const r1_1 = async params => {
  return _axios.post(Api.r1_1, params)
}
export const total_user = async params => {
  return _axios.post(Api.total_user, params)
}
export const total_transaction_user = async params => {
  return _axios.post(Api.total_transaction_user, params)
}
