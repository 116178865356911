<template>
  <div class="panel" :class="className">
    <div class="panel_header">
      <div class="panel_header_box">
        <img
          class="panel_header_icon"
          src="/images/global/ekPanel/title-icon.png"
          alt=""
        />
        <div class="panel_header_title" v-if="title">{{ title }}</div>
        <div class="panel_header_titles" v-else>
          <span
            class="normal"
            :class="currIndex == index ? 'active' : ''"
            v-for="(item, index) in titles"
            :key="index"
            @click="handleTitle(index)"
            >{{ item }}</span
          >
        </div>
        <img
          class="panel_header_line"
          src="/images/global/ekPanel/title-line.png"
          alt=""
        />
      </div>
    </div>
    <div class="panel_body">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currIndex: 0
    }
  },
  watch: {
    segementIndex: {
      handler(val) {
        if (val) {
          this.currIndex = val
        }
      },
      immediate: true
    }
  },
  props: {
    titles: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: ''
    },
    className: {
      type: String,
      default: ''
    },
    segementIndex: {
      type: Number,
      default: null
    }
  },
  methods: {
    handleTitle(i) {
      this.currIndex = i
      this.$emit('click', i)
    }
  }
}
</script>

<style scoped lang="scss">
$headerHeight: 48px;
$titleWidth: 150px;

.panel {
  @include flex(column);
  &_header {
    height: $headerHeight;
    width: 100%;
    align-items: center;
    position: relative;
    background: url('/images/global/ekPanel/title-bg.png') no-repeat;
    background-size: 100% 100%;
    &_box {
      display: flex;
      align-items: center;
      height: 40px;
    }
    &_title {
      padding: 0 30px 0 8px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font-size: 24px;
      background: linear-gradient(to top, #ef9c00, #fff);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    &_titles {
      @include flex();
      padding: 0 30px 0 8px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font-size: 24px;
      .normal {
        color: #d6d6d6;
        // padding: 0 20px;
        cursor: pointer;
        position: relative;
        @include flex();
        align-items: center;
      }
      .normal:nth-child(odd) {
        padding-right: 20px;
      }
      .normal:nth-child(even) {
        padding-left: 20px;
      }
      .normal:first-child::after {
        content: '';
        right: 0;
        width: 1px;
        height: 70%;
        position: absolute;
        // border-right: 1px solid #b5b5b5;
        background: #b5b5b5;
      }
      .active {
        background: linear-gradient(to top, #ef9c00, #fff);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    &_icon {
      width: 23px;
      height: 23px;
      margin-left: 20px;
    }
    &_line {
      width: 282px;
      height: 20px;
    }
  }
  &_body {
    flex: 1;
    z-index: 1;
    position: relative;
    background: url('/images/global/ekPanel/box-bg.png') no-repeat;
    background-size: 100% 100%;

    // div {
    //   width: 100%;
    // }
  }
}
</style>
