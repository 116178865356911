import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import createLogger from 'vuex/dist/logger'
import { fetchFindByType } from '@/api'

const debug = process.env.NODE_ENV !== 'production'
Vue.use(Vuex)

const state = {
  headerType: 'ov',
  menuType: 'br',
  dialogType: null,
  closeDialog: null,
  cancelTokenArr: [],

  // 数据字典列表
  dictionaryList: [
    {
      busintypeId: '',
      businId: '',
      businName: ''
    }
  ],
  dictionaryMap: {},
  dicLoading: {}
}

const mutations = {
  SET_HEADER_TYPE(state, type) {
    state.headerType = type
    // state.popType = 'close';
  },
  SET_MENU_TYPE(state, type) {
    state.menuType = type
    // state.popType = 'close';
  },
  PUSH_CANCEL(state, cancel) {
    state.cancelTokenArr.push(cancel)
  },
  CLEAR_CANCEL(state) {
    state.cancelTokenArr.map(item => {
      item.cancelToken('路由跳转请求终止')
    })
    state.cancelTokenArr = []
  },

  setDic(state, config) {
    Vue.set(state.dictionaryMap, config.dicType, config.resultData)
  }
}
const actions = {
  setHeaderType({ commit }, type) {
    commit('SET_HEADER_TYPE', type)
  },
  setMenuType({ commit }, type) {
    commit('SET_MENU_TYPE', type)
  },
  setPushCancel({ commit }, data) {
    commit('PUSH_CANCEL', data)
  },
  setClearCancel({ commit }, data) {
    commit('CLEAR_CANCEL', data)
  },

  fresh({ state, commit }, dicType) {
    if (state.dictionaryMap[dicType] !== undefined) {
      return
    }
    // 正在加载的不进行加载
    if (state.dicLoading[dicType] === 'true') {
      console.log('fresh dic is loading')
      return
    } else {
      console.log('fresh begin loading', dicType)
      state.dicLoading[dicType] = 'true'
    }
    console.log('fresh dic', dicType)
    fetchFindByType(dicType).then(res => {
      // console.log('resulteData', res.resultData)
      state.dicLoading[dicType] = 'false'
      const resultData = res.resultData
      commit('setDic', {
        dicType,
        resultData
      })
    })
  }
}

export default new Vuex.Store({
  state,
  mutations,
  getters,
  actions,
  modules: {},
  plugins: debug ? [createLogger()] : []
})
