<template>
  <div class="welcome">
    <img :src="imgUrl" alt="" v-if="imgUrl" />
    <div class="item" @click="handleItem('dashboard')"></div>
    <div class="item" @click="handleItem('video')"></div>
  </div>
</template>

<script>
import { fetchData, fetchImageUrl } from '@/api'
export default {
  data() {
    return {
      imgUrl: ''
    }
  },
  mounted() {
    this.fetchImage()
  },
  methods: {
    async fetchImage() {
      let _this = this
      const { resultCode, resultData } = await fetchData('ioc_welcome')
      if (resultCode === '200' && resultData) {
        console.log('ioc_welcome', resultData)
        const data = resultData.content[0]
        if (data.detail_img.length > 0) {
          fetchImageUrl(data.detail_img).then(res => {
            _this.imgUrl = res
          })
        }
      }
    },
    handleItem(type) {
      if (type == 'video') {
        this.$router.push({ path: `/video` }).catch(() => {})
        this.$eventBus.$emit('push-router-index', 1)
      } else if (type == 'dashboard') {
        this.$router.push({ path: `/ov` }).catch(() => {})
        this.$store.dispatch('setHeaderType', 'ov')
        this.$eventBus.$emit('push-router-index', 2)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.welcome {
  @include flex();
  box-sizing: border-box;
  height: 100%;
  align-items: center;
  z-index: 2;
  .item {
    position: absolute;
    width: 100px;
    height: 90%;
    cursor: pointer;
  }
  .item:nth-child(1) {
    left: 0;
  }
  .item:nth-child(2) {
    right: 0;
  }

  img {
    width: 100%;
    height: 100%;
  }
}
</style>
