<template>
  <div class="ek-item-info">
    <span class="title" :style="{ fontSize }">{{ title }}</span>
    <span class="value" v-if="value" :style="{ fontSize }">{{ value }}</span>
  </div>
</template>

<script>
export default {
  data() {
    return {
      iStyle: null
    }
  },
  props: {
    title: {
      type: String,
      deafault: ''
    },
    value: {
      type: String,
      deafault: ''
    },
    fontSize: {
      type: String,
      deafault: '22px'
    }
  },
  methods: {}
}
</script>

<style scoped lang="scss">
.ek-item-info {
  @include flex();
  align-items: center;
  .title,
  .value {
    font-size: 22px;
  }
}
</style>
