<template>
  <div class="ek-total">
    <div class="box">
      <div
        class="item"
        v-for="(item, index) in list"
        :key="index"
        @click="clickEvent(item, index)"
        :style="currType == 'std' ? 'cursor:pointer;' : ''"
      >
        <div
          class="t-box"
          :style="{ width, height }"
          :class="curLable === item.label ? 'active' : ''"
        >
          <img
            v-if="item.iconStyle"
            :style="item.iconStyle"
            :src="`/images/global/ekTotal/${currType}/${index + 1}.png`"
            alt=""
          />
          <span
            :style="{ 'margin-left': item.iconStyle ? '18px' : '' }"
            class="title"
            >{{ item.label }}</span
          >
        </div>
        <div
          class="v-box"
          :style="{ width, height }"
          :class="curLable === item.label ? 'active' : ''"
        >
          <span
            class="num"
            v-if="isConutTo"
            :class="curLable === item.label ? 'active' : ''"
          >
            <ICountUp
              :ref="`iCountUp-${headerType}`"
              :options="{
                separator: '',
                duration: 2,
                decimalPlaces: item.decimals || 0
              }"
              :endVal="item.num || 0"
            />
          </span>
          <span class="num" v-if="!isConutTo">{{ item.num }}</span>
          <span class="unit">{{ item.unit }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { _axios } from '@/utils/request'
import { fetchData } from '@/api'
import { total_dev_online } from '@/api/other'
import {
  cs_total_top_three,
  cs_total_car,
  cs_total_store,
  cs_total_alarm_event
} from '@/api/cs'
import { r1_1, total_user } from '@/api/do'

export default {
  data() {
    return {
      currType: '',
      list: [],
      curLable: '',
      curStdLegendType: 'afjk',
      stdList: [],
      stdBridgeWarn: false,
      countUpList: [],
      sumTotalList: [],
      options: {
        separator: '',
        duration: 2
      },
      addSumCount: 0
      // isConutTo: false
    }
  },
  props: {
    width: {
      type: String,
      default: '240px'
    },
    height: {
      type: String,
      default: '64px'
    },
    decimals: {
      type: Number,
      default: 0
    },
    isConutTo: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(['headerType', 'menuType']),
    iCountUp() {
      return this.$refs[`iCountUp-${this.headerType}`]
    }
  },
  mounted() {
    this.watchHeaderType(this.headerType)
  },
  methods: {
    async watchHeaderType(type) {
      this.currType = type
      this.list = []
      switch (type) {
        case 'ov':
          this.watchMenuType(this.menuType)
          break
        case 'iw':
          this.list = [
            // {
            //   label: '工单数',
            //   num: 0,
            //   unit: '单'
            // },
            // {
            //   label: '代办工单',
            //   num: 0,
            //   unit: '单'
            // },
            {
              label: '商铺总数',
              num: 0,
              decimals: 0,
              unit: '家'
            },
            {
              label: '空置商铺',
              num: 0,
              decimals: 0,
              unit: '家'
            },
            {
              label: '空置率',
              num: 0,
              decimals: 2,
              unit: '%'
            },
            {
              label: '在线设备',
              num: 0,
              decimals: 0,
              unit: '个'
            }
          ]
          await this.fetchData('work_order_stat')
          await this.fetchData('asset_stat')
          await this.fetchData()
          break
        case 'cs':
          this.list = [
            {
              label: '实时客流',
              num: 0,
              sourceNum: 0,
              unit: '人',
              iconStyle: { width: '36px', height: '30px' }
            },
            {
              label: '今日累计客流',
              num: 0,
              sourceNum: 0,
              unit: '人',
              iconStyle: { width: '36px', height: '30px' }
            },
            {
              label: '实时车流',
              num: 0,
              sourceNum: 0,
              unit: '辆',
              iconStyle: { width: '36px', height: '29px' }
            },
            {
              label: '商家数量',
              num: 0,
              unit: '家',
              iconStyle: { width: '36px', height: '33px' }
            },
            {
              label: '在线设备',
              num: 0,
              unit: '个',
              iconStyle: { width: '36px', height: '36px' }
            },
            {
              label: '预警事件',
              num: 0,
              unit: '件',
              iconStyle: { width: '36px', height: '36px' }
            }
          ]
          this.fetchData()
          break
        case 'do':
          this.list = [
            {
              label: '平台用户',
              num: 0,
              unit: '人',
              iconStyle: { width: '36px', height: '30px' }
            },
            {
              label: '今日新增用户数',
              num: 0,
              unit: '人',
              iconStyle: { width: '36px', height: '30px' }
            },
            {
              label: '日活用户',
              num: 0,
              unit: '人',
              iconStyle: { width: '36px', height: '29px' }
            },
            {
              label: '发布文章',
              num: 0,
              unit: '篇',
              iconStyle: { width: '33px', height: '36px' }
            },
            {
              label: '发布活动',
              num: 0,
              unit: '场',
              iconStyle: { width: '36px', height: '36px' }
            },
            {
              label: '线上推广曝光度',
              num: 0,
              unit: '次',
              iconStyle: { width: '36px', height: '32px' }
            }
          ]
          this.fetchData()
          break
        case 'std':
          this.list = [
            {
              label: '重点区域',
              num: 3,
              unit: '',
              businessScenario: '10'
            },
            {
              label: '重点路口',
              num: 8,
              unit: '',
              businessScenario: '20'
            },
            {
              label: '广场区',
              num: 17,
              unit: '',
              businessScenario: '30'
            },
            {
              label: '高点鹰眼',
              num: 3,
              unit: '',
              businessScenario: '40'
            },
            {
              label: '卫生点',
              num: 24,
              unit: '',
              businessScenario: '50'
            }
          ]
          this.$eventBus.$off('std-legend')
          this.$eventBus.$off('std-r1-iswarning-total')

          setTimeout(() => {
            //过街天桥是否预警

            this.$eventBus.$on('std-r1-iswarning-total', res => {
              this.stdBridgeWarn = res.flag
              this.curStdLegendType = 'afjk'
              this.curLable = ''
              this.fetchStdAll()
            })

            this.$eventBus.$on('std-legend', e => {
              this.curStdLegendType = e.type
              console.log(e, 'std total ceshi sssssssssssssssssssssss')
              if (e.type !== 'rlrl') {
                this.fetchStdAll()
              }

              this.curLable = ''
            })
          }, 300)

          this.cameraNumByScenario()
          this.curLable = ''
          this.fetchStdAll()
          break
      }
    },
    watchMenuType(type) {
      switch (type) {
        case 'br':
          this.currType = type
          this.list = [
            {
              label: '占地面积',
              num: 0,
              unit: '平方公里',
              iconStyle: { width: '36px', height: '27px' }
            },
            {
              label: '主街长度',
              num: 0,
              unit: '米',
              iconStyle: { width: '36px', height: '34px' }
            },
            {
              label: '年客流量',
              num: 0,
              unit: '万人',
              iconStyle: { width: '36px', height: '30px' }
            },
            {
              label: '营业额',
              num: 0,
              unit: '万元',
              iconStyle: { width: '36px', height: '32px' }
            },
            {
              label: '商家数',
              num: 0,
              unit: '家',
              iconStyle: { width: '36px', height: '33px' }
            },
            {
              label: '平均可吸纳就业',
              num: 0,
              unit: '人/万平方米',
              iconStyle: { width: '36px', height: '34px' }
            },
            {
              label: '配套车位',
              num: 0,
              unit: '个',
              iconStyle: { width: '36px', height: '29px' }
            }
          ]
          this.fetchData()
          break
      }
    },
    async fetchData(code = null, add = false) {
      this.countUpList = []
      this.sumTotalList = []
      if (code) {
        this.apiFn(fetchData, code).then(res => {
          this.formatItemForData(code, res.content)
        })
      } else {
        switch (this.currType) {
          case 'br':
            this.ov_br_total().then(res => {
              this.formatItemForData('ov_br_total', res)
            })
            break
          case 'iw':
            this.apiFn(total_dev_online).then(res => {
              this.formatItemForData(code, res)
            })
            break
          case 'cs':
            if (add) {
              await this.apiFn(cs_total_top_three).then(res => {
                this.formatItemForData('cs_total_top_three', res, add)
              })
              // await this.apiFn(cs_total_car).then(res => {
              //   this.formatItemForData('cs_total_car', res, add)
              // })
            } else {
              await this.apiFn(cs_total_top_three).then(res => {
                this.formatItemForData('cs_total_top_three', res)
              })
              await this.apiFn(cs_total_car).then(res => {
                this.formatItemForData('cs_total_car', res)
              })
              await this.apiFn(cs_total_store).then(res => {
                this.formatItemForData('cs_total_store', res)
              })
              await this.apiFn(total_dev_online).then(res => {
                this.formatItemForData('total_dev_online', res)
              })
              await this.apiFn(cs_total_alarm_event).then(res => {
                this.formatItemForData('cs_total_alarm_event', res)
              })
              await this.fetchData('asset_stat')
            }

            break
          case 'do':
            await this.apiFn(total_user).then(res => {
              this.formatItemForData('do_total_user', res)
            })
            await this.do_total_activity().then(res => {
              this.formatItemForData('do_total_activity', res)
            })
            break
        }
      }
    },
    async apiFn(api, code) {
      const { resultCode, resultData } = await api(code)
      if (resultCode === '200' && resultData) {
        return resultData
      }
    },
    async ov_br_total() {
      const { resultCode, resultData } = await this.$axios.post(
        '/cmsv2/content/page',
        {
          needDraft: false,
          typeCode: 'taidong_general',
          status: '1'
        }
      )
      if (resultCode === '200' && resultData) {
        return resultData
      }
    },
    async do_total_activity() {
      const { resultCode, resultData } = await r1_1({})
      if (resultCode === '200' && resultData) {
        return resultData
      }
    },
    formatItemForData(code, data, add) {
      console.log(code, data)
      switch (this.currType) {
        case 'br':
          this.list[0].num = data.content[0].acreage
          this.list[1].num = data.content[0].street_length
          this.list[2].num = data.content[0].passengeFlow
          this.list[3].num = data.content[0].turnover
          this.list[4].num = data.content[0].store_num
          this.list[5].num = data.content[0].job_num
          this.list[6].num = data.content[0].park_num
          break
        case 'iw':
          if (code == 'work_order_stat') {
            // this.list[0].num = data[0].total
            // this.list[1].num = data[0].todo_num
          } else if (code == 'asset_stat') {
            this.list[0].num = data[0].total
            this.list[1].num = data[0].vacancy_num
            this.list[2].num = data[0].tongbi
          } else {
            this.list[3].num = data
          }
          break
        case 'cs':
          {
            if (code == 'cs_total_top_three') {
              if (add) {
                let num1 = data.realTimeTourist - this.list[0].num
                let num2 = data.todayTotalTourist - this.list[1].num
                if ((num1 > 0 && num1 < 15) || (num2 > 0 && num2 < 15)) {
                  setTimeout(() => {
                    this.fetchData(null, true)
                  }, 1000 * 60 * 1)
                } else {
                  let arr1 = this.randomFigure(num1, 15, 1)
                  this.sumTotalList.push({
                    index: 0,
                    list: arr1
                  })
                  let arr2 = this.randomFigure(num2, 15, 1)
                  this.sumTotalList.push({
                    index: 1,
                    list: arr2
                  })
                  this.fetchAddItemTotal(this.sumTotalList, null)
                }
              } else {
                this.list[0].num = parseInt(data.realTimeTourist * 0.955)
                this.list[1].num = parseInt(data.todayTotalTourist * 0.99)
                if (this.addSumCount == 0) {
                  let num1 = parseInt(data.realTimeTourist * 0.045)
                  let num2 = parseInt(data.todayTotalTourist * 0.01)
                  let arr1 = this.randomFigure(num1, 15, parseInt(num1 * 0.05))
                  this.sumTotalList.push({
                    index: 0,
                    list: arr1
                  })
                  let arr2 = this.randomFigure(num2, 15, parseInt(num2 * 0.05))
                  this.sumTotalList.push({
                    index: 1,
                    list: arr2
                  })
                  this.fetchAddItemTotal(this.sumTotalList, null)
                }
              }
              console.log(this.sumTotalList)
              this.addSumCount++
            } else if (code == 'cs_total_car') {
              this.list[2].num = data
              this.list[2].sourceNum = data
            } else if (code == 'cs_total_store') {
              // this.list[3].num = data
            } else if (code == 'total_dev_online') {
              this.list[4].num = data
            } else if (code == 'cs_total_alarm_event') {
              this.list[5].num = data
            } else if (code == 'asset_stat') {
              this.list[3].num = data[0].total
            }
          }
          break
        case 'do': {
          if (code == 'do_total_user') {
            this.list[0].num = data.totalNum
            this.list[1].num = data.todayAddNum
            this.list[2].num = data.todayLoginNum
          } else if (code == 'do_total_activity') {
            this.list[3].num = data.articleNum
            this.list[4].num = data.activityNum
            this.list[5].num = data.readingCount
          }
        }
      }
    },
    clickEvent(item, index) {
      if (this.currType === 'std') {
        //&&
        this.curLable = item.label
        this.$eventBus.$emit('std-curArea', {
          areaType: item.businessScenario,
          areaLable: item.label,
          index: index
        })

        if (this.curStdLegendType == 'rlrl') {
          this.$eventBus.$emit('std-legedAFJK', true)
          this.$eventBus.$emit('std-legedAFJK-map', true)
          this.getStdData0(item, 0)
        } else {
          this.getStdData0(item, index)
        }
      } else {
        this.curLable = ''

        if (this.isConutTo) {
          console.log(this.iCountUp)
          console.log(this.iCountUp[index].options.duration)
          // let number = this.list[index].num
          // this.list[index].num = 0
          // this.iCountUp[index].reset()
          // setTimeout(() => {
          //   this.list[index].num = number
          // }, 100)
        }
      }

      // console.log(this.currType, this.curLable, 'change事件')
    },
    async getStdData0(item, index) {
      this.stdList = await this.fetchStdData(index, item.businessScenario)
      this.$eventBus.$emit('ov-std-deviceList', this.stdList)
    },
    async fetchStdAll() {
      const l1 = await this.fetchStdData(0, '10')
      const l2 = await this.fetchStdData(1, '20')
      const l3 = await this.fetchStdData(2, '30')
      const l4 = await this.fetchStdData(3, '40')
      const l5 = await this.fetchStdData(4, '50')
      this.stdList = [...l1, ...l2, ...l3, ...l4, ...l5]
      // this.stdList = [...l1]
      this.$eventBus.$emit('ov-std-deviceList', this.stdList)
      this.$eventBus.$emit('ov-std-deviceList1', this.stdList)
      this.$eventBus.$emit('ov-std-deviceList2', this.stdList)
    },
    async cameraNumByScenario() {
      let that = this
      const { resultCode, resultData } = await _axios.get(
        '/stat/deviceInfo/cameraNumByScenario/'
      )
      if (resultCode === '200' && resultData) {
        console.log(resultData, '分区域设备统计')
        resultData.forEach((item, index) => {
          that.list[index].num = item.cameraNum
        })
      }
    },
    async fetchStdData(index, curServerArea) {
      const temList = []
      const { resultCode, resultData } = await _axios.post(
        '/stat/deviceInfo/deviceListBySource/',
        {
          source: '2',
          businessScenario: curServerArea
        }
      )
      if (resultCode === '200' && resultData) {
        // console.log(resultData, '设备列表')
        resultData.map(item => {
          if (item.isDisplay && Number(item.isDisplay) === 0) return
          if (item.deviceType === 'pf_camera') {
            if (item.deviceLocation.indexOf('天桥') !== -1) {
              // console.log(item)
              item.alarm = this.stdBridgeWarn
            }
            temList.push({
              labeltype: 'rlrl',
              legendType: 'afjk',
              deviceType: item.deviceType,
              lnglat: [Number(item.longitude), Number(item.latitude)],
              status: item.deviceStatus,
              cameraUrl: item.cameraUrl,
              // alarm:this.stdBridgeWarn,
              alarm: item.alarm,
              deviceName: item.deviceName,
              deviceLocation: item.deviceLocation,
              allData: item
            })
          } else if (item.deviceType === 'se_camera') {
            // 安防监控
            temList.push({
              labeltype: 'afjk',
              legendType: 'afjk',
              deviceType: item.deviceType,
              lnglat: [Number(item.longitude), Number(item.latitude)],
              status: item.deviceStatus,
              cameraUrl: item.cameraUrl,
              alarm: this.stdBridgeWarn,
              allData: item
            })
            // console.log(item)
          } else if (item.deviceType === 'handheld') {
            //手持单兵
            temList.push({
              labeltype: 'scdb',
              legendType: 'scdb',
              deviceType: item.deviceType,
              lnglat: [Number(item.longitude), Number(item.latitude)],
              status: item.deviceStatus,
              cameraUrl: item.cameraUrl,
              alarm: item.deviceStatus,
              allData: item
            })
          } else if (item.deviceType == 'ee_camera') {
            temList.push({
              labeltype: 'gdyy',
              legendType: 'afjk',
              deviceType: item.deviceType,
              lnglat: [Number(item.longitude), Number(item.latitude)],
              status: item.deviceStatus,
              cameraUrl: item.cameraUrl,
              alarm: this.stdBridgeWarn,
              allData: item
            })
          }
        })
        return temList
      } else {
        console.log('无数据')
      }
    },
    // 数字滚动定时器
    fetchAddItemTotal(list, code) {
      let count = 0
      let timer = null
      let _this = this
      switch (this.currType) {
        case 'cs':
          {
            timer = setInterval(() => {
              // 累加超过n次停止
              if (count >= 15) {
                clearInterval(timer)
                timer = null
              } else {
                _this.list[0].num += list[0].list[count]
                _this.list[1].num += list[1].list[count]
                console.log('累加次数：', count + 1)
              }
              if (!timer) {
                console.log('累加结束等待下次请求')
                setTimeout(() => {
                  _this.fetchData(code, true)
                }, 1000 * 60 * 7.5)
              }
              count++
            }, 1000 * 30)
          }
          break
      }

      this.$once('hook:beforeDestroy', () => {
        clearInterval(timer)
        timer = null
      })
    },

    /**
     *随机范围
     *
     * @param {*} lowerValue
     * @param {*} upperValue
     * @return {*}
     */
    randomFrom(lowerValue, upperValue) {
      return Math.floor(
        Math.random() * (upperValue - lowerValue + 1) + lowerValue
      )
    },
    /**
     *数字随机分割
     *
     * @param {*} number
     * @param {*} count
     * @param {*} randomRange
     * @return {*}
     */
    randomFigure(number, count, randomRange) {
      let arr = []
      let num = number / count
      while (count > 0) {
        let res =
          count == 1 ? number : this.randomFrom(randomRange, Math.floor(num))
        arr.push(parseInt(res))
        count--
        number -= res
        num = number / count
      }
      var len = arr.length
      for (var i = 0; i < len - 1; i++) {
        var index = parseInt(Math.random() * (len - i))
        var temp = arr[index]
        arr[index] = arr[len - i - 1]
        arr[len - i - 1] = temp
      }
      return arr
    }
  }
}
</script>

<style scoped lang="scss">
.ek-total {
  @include flex();
  justify-content: center;
  pointer-events: all;
}
.box {
  @include flex();
}
.item {
  @include flex(column);
  margin-right: 7px;
  font-size: 24px;
  background: linear-gradient(
    90deg,
    rgba(42, 215, 255, 0) 0%,
    #1b2f37 50%,
    rgba(42, 215, 255, 0) 100%
  );
}
.item:last-child {
  margin-right: 0;
}

.t-box {
  @include flex();
  align-items: center;
  justify-content: center;
  position: relative;
}
.v-box {
  @include flex();
  align-items: baseline;
  justify-content: center;
  position: relative;
  line-height: 64px;
}
.t-box {
  background: url('/images/global/ekTotal/label-bg.png') no-repeat;
  background-size: 100% 100%;
  .title {
    // margin-left: 18px;
  }
}

.v-box {
  .num {
    font-size: 36px;
    color: #00ffff;
    font-weight: bold;
    font-family: PangMenZhengDao;
  }
  .unit {
    margin-left: 17px;
  }
}

.t-box::after,
.v-box::after {
  content: '';
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 1px;
  background: linear-gradient(
    244deg,
    rgba($color: #81c5c8, $alpha: 0) 0%,
    rgba($color: #81c5c8, $alpha: 1) 50%,
    rgba($color: #81c5c8, $alpha: 0) 100%
  );
}
.active {
  color: #e99803;
}
.t-box.active {
  background: url('/images/global/ekTotal/label-bg-active.png') no-repeat;
  background-size: 100% 100%;
}
.t-box.active::after,
.v-box.active::after {
  background: linear-gradient(
    244deg,
    rgba($color: #e99803, $alpha: 0) 0%,
    rgba($color: #e99803, $alpha: 1) 50%,
    rgba($color: #e99803, $alpha: 0) 100%
  );
}
.num.active {
  color: #e99803;
}
</style>
