<template>
  <div class="ek-select">
    <el-select
      v-model="value"
      :placeholder="placeholder"
      @change="handleSelect"
      :style="{ width }"
      popper-class="selectInfo"
    >
      <el-option
        v-for="item in list"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      >
      </el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  data() {
    return {
      value: 0
    }
  },
  computed: {},
  props: {
    list: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: '请选择'
    },
    width: {
      type: String,
      default: '110px'
    },
    defaultVal: {
      type: Number,
      default: 0
    },
    selectVal:{
      type: Number,
      default:0
    }
  },
  watch: {
   
    defaultVal: {
      handler(val) {
        if (!val) {
          this.value = 0
        }
      },
      immediate: true
    },
     selectVal:{
        handler(val) {
        this.value = val
      },
      immediate: true
      },
  },
  methods: {
    handleSelect() {
      this.$emit('click', this.value)
    }
  }
}
</script>

<style scoped lang="scss">
.ek-select {
  height: 34px;
}
</style>

<style lang="scss">
.ek-select {
  .el-select,
  .el-input,
  .el-input__inner {
    @include flex();
    height: 34px;
  }

  .el-input__inner {
    background: transparent;
    color: #fff;
    border-radius: 6px;
    // font-size: 14px;
  }
  .el-select,
  .el-input__inner,
  .el-input__inner:focus,
  .el-input__inner:hover,
  .el-select:hover .el-input__inner,
  .el-input.is-focus .el-input__inner {
    border-color: #ef9c00;
  }

  .el-input__suffix,
  .el-input__suffix-inner {
    @include flex();
    align-items: center;
    justify-content: center;
  }
  .el-input__suffix {
    margin-right: 10px;
  }
  .el-icon-arrow-up {
    width: 15px;
    height: 10px;
    background: url('/images/global/ekSelect/up.png') no-repeat;
    background-size: 100% 100%;
  }
  .el-icon-arrow-up::before {
    content: '';
  }
}
.selectInfo {
  border: none !important;
  background-color: #696969;
  @include flex(column);
  .el-scrollbar {
    flex: 1;
    padding-bottom: 20px;
  }
  .el-select-dropdown__wrap {
    overflow-x: hidden;
  }
  .el-select-dropdown__item {
    color: #fff;
  }
  .el-select-dropdown__item.selected {
    // color: #00ffff;
    background-color: #808080;
  }
  .el-select-dropdown__item:hover,
  .el-select-dropdown__item.hover {
    background-color: #808080;
  }
  .popper__arrow,
  .popper__arrow::after {
    border-bottom-color: #808080 !important;
  }
}
</style>
