'use strict'

import Vue from 'vue'
import axios from 'axios'
import { ServerConfig } from './config'
// import { Notification } from 'element-ui'
import { Message } from 'element-ui';
import { fetchToken } from '@/api/login'
import store from '@/store'

let config = {
  baseURL: ServerConfig.baseURL
}
const whiteUrls = ['/auth']

const _axios = axios.create(config)

_axios.interceptors.request.use(
  config => {
    config.cancelToken = new axios.CancelToken(cancel => {
      store.dispatch('setPushCancel', { cancelToken: cancel })
    })

    let token = sessionStorage.getItem('token')
    if (!whiteUrls.includes(config.url)) {
      config.headers['Authorization'] = token
      config.headers['Cms-V2-Namespace'] = ServerConfig.spaceCode
    }
    return config
  },
  function(error) {
    return Promise.reject(error)
  }
)

_axios.interceptors.response.use(
  response => {
    let code = response.data.resultCode
    if (code == '200' || code == '0') {
      console.log(
        `请求地址：${response.config.url}, 参数：${response.config.data}`
      )
      console.log(response.data)
      return response.data
    } else if (code == '401') {
      fetchToken().then(token => {
        sessionStorage.setItem('token', token)
      })
    } else {
      console.log(response.data)
      Message({
        message:response.data.message,
        center: true,
        showClose: false
      })
      // Notification({
      //   title: response.data.message,
      //   showClose: false,
      //   position: 'bottom-right',
      //   type: 'info'
      // })
      if(code)  return {resultCode:code, resultData:null }
    }
  },
  function(error) {
    if (axios.isCancel(error)) {
      // 为了终结promise链 (实际请求不会走到.catch(rej=>{}),这样就不会触发错误提示之类的)
      return new Promise(() => {})
    } else {
      return Promise.reject(error)
    }
  }
)

Plugin.install = function(Vue) {
  Vue.axios = _axios
  window.axios = _axios
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios
      }
    },
    $axios: {
      get() {
        return _axios
      }
    }
  })
}

Vue.use(Plugin)

export default Plugin
export { _axios } // 同时导出实例
