import { _axios } from '@/utils/request'
export const fetchData = async (
  typeCode,
  subCode,
  page = 0,
  size = 10,
  status = '1'
) => {
  return await _axios.post('/cmsv2/content/page', {
    needDraft: true,
    page,
    size,
    typeCode,
    subTypeCode: subCode,
    status
  })
}

// 根据Code获取图片路径
export const fetchImageUrl = async code => {
  const { resultCode, resultData } = await _axios.post(
    `/attach/find/${code}`,
    {}
  )
  if (resultCode === '200' && resultData) {
    return resultData.url || ''
  }
  return ''
}
// 根据code查询详情
export const fetchDataByCode = async code => {
  return await _axios.post(`/cmsv2/content/findById/${code}`, {})
}

export const fetchFindByType = async (busiTypeId, delFlag = false) => {
  return await _axios.post(`/dictionary/findByType`, { busiTypeId, delFlag })
}
