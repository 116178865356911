import Vue from 'vue'
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'

const requireComp = require.context(
  './components', // 目录
  false, // 是否查询子目录
  /Ek[A-Z]\w+\.(vue)$/ // 匹配文件名 Ek是前缀
)
requireComp.keys().forEach(fileName => {
  const compConfig = requireComp(fileName)
  const componentName = upperFirst(
    camelCase(
      fileName
        .split('/')
        .pop()
        .replace(/\.\w+$/, '')
    )
  )
  Vue.component(componentName, compConfig.default || compConfig)
})
