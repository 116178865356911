<template>
  <div class="vidoe-desc">
    <video
      ref="videoPlayer"
      controls="controls"
      style="object-fit:fill;width:100%;height:100%;"
      :muted="false"
      autoplay
      preload="auto"
    >
      <source :src="currentSourceSrc" type="video/mp4" />
    </video>
    <div class="item" @click="handleItem('dashboard')"></div>
    <div class="item" @click="handleItem('welcome')"></div>
  </div>
</template>

<script>
// import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      currentSourceSrc: '',
      player: null
    }
  },
  mounted() {
    this.currentSourceSrc = 'http://localhost:3020/videos/desc.mp4'
    this.$refs.videoPlayer.addEventListener('ended', this.onPlayerEnded)
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose()
    }
  },
  methods: {
    async fetchVideo() {
      // const { resultData } = await this.$axios.post('/cmsv2/content/page', {
      //   needDraft: true,
      //   page: 0,
      //   size: 10,
      //   typeCode: 'hfwelcome'
      // })
      this.currentSourceSrc = 'http://localhost:3020/videos/desc.mp4'
      // console.log(resultData.content)
    },
    onPlayerEnded() {
      this.$refs.videoPlayer.load()
    },
    handleItem(type) {
      if (type == 'welcome') {
        this.$router.push({ path: `/welcome` }).catch(() => {})
        this.$eventBus.$emit('push-router-index', 0)
      } else if (type == 'dashboard') {
        this.$router.push({ path: `/ov` }).catch(() => {})
        this.$store.dispatch('setHeaderType', 'ov')
        this.$eventBus.$emit('push-router-index', 2)
      }
    }
  }
}
</script>

<style scoped lang="scss" rel="stylesheet/scss">
.vidoe-desc {
  width: 100%;
  height: 100%;
  background-color: #000;
  @include flex();
  // align-items: center;
  position: relative;
  z-index: 2;
  .item {
    position: absolute;
    width: 100px;
    height: 90%;

    cursor: pointer;
  }
  .item:nth-child(1) {
    left: 0;
  }
  .item:nth-child(2) {
    right: 0;
  }
}
</style>

<style lang="scss">
.vjs-big-play-button {
  display: none !important;
}
.vjs-control-bar {
  display: none !important;
}

::deep .video-js .vjs-tech {
  object-fit: fill;
}
::deep .vjs-poster {
  background-size: cover;
}
video::-webkit-media-controls {
  display: none !important;
}
</style>
