<template>
    <div class="ek-legend"
         v-if="list.length > 0"
         :style="{
      background: `url(/images/global/ekLegend/${
        list.length > 6 ? 'big' : 'small'
      }.png)`,
      'background-size': '100% 100%'
    }">
        <div class="box"
             v-if="currType == 'br' || currType == 'std'">
            <span class="t1">图例：</span>
            <div class="legend"
                 @click="handleLegend(index)"
                 v-for="(item, index) in list"
                 :key="index">
                <img class="icon"
                     :src="
            `/images/global/ekLegend/ov/br/${
              item.type == 'all'
                ? currIndex == index
                  ? item.type + '-s'
                  : item.type + '-u'
                : item.type
            }.png`
          "
                     alt="" />
                <span class="name"
                      :class="currIndex == index ? 'active' : ''">{{
          item.name
        }}</span>
            </div>
        </div>
        <div class="box"
             v-if="currType == 'bfp'">
            <span class="t1">图例：</span>
            <div class="legend"
                 @click="handleLegend(index)"
                 v-for="(item, index) in list"
                 :key="index">
                <img class="icon"
                     :src="
            `/images/global/ekLegend/${
              currType == 'bfp' ? '/ov/bfp/' : currType + '/'
            }${index + 1}-${currIndex == index ? 's' : 'u'}.png`
          "
                     alt="" />
                <span class="name"
                      :class="currIndex == index ? 'active' : ''">{{
          item.name
        }}</span>
            </div>
        </div>
        <div class="box"
             v-if="currType == 'iw'">
            <span class="t1">图例：</span>
            <div class="legend"
                 @click="handleLegend(index)"
                 v-for="(item, index) in list"
                 :key="index">
                <img class="icon"
                     :src="
            `/images/global/ekLegend/${
              currType == 'iw' || currType == 'cs' ? 'cs' : ''
            }/${item.type}${currIndex == index ? '-s' : '-u'}.png`
          "
                     alt="" />
                <span class="name"
                      :class="currIndex == index ? 'active' : ''">{{
          item.name
        }}</span>
            </div>
            <div class="dev-state">
                <span>设备状态：</span>
                <div class="dev-state-box">
                    <div class="dev-state-text">
                        <span>在线</span>
                    </div>
                    <div class="dev-state-text">
                        <span>异常</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="box"
             v-if="currType == 'cs'">
            <span class="t1">图例：</span>
            <div class="legend"
                 @click="handleLegend(index)"
                 v-for="(item, index) in list"
                 :key="index"
                 style="padding: 10px 40px 0 20px;;">
                <img class="icon"
                     style="width: 28px;height: 33px;"
                     :src="
            `/images/global/ekLegend/cs/${item.type}${
              currIndex == index ? '-s' : '-u'
            }.png`
          "
                     alt="" />
                <span class="name"
                      style="font-size: 20px;"
                      :class="currIndex == index ? 'active' : ''">{{ item.name }}</span>
            </div>
        </div>
        <div class="box_row"
             v-if="currType == 'jqdj'">
            <div class="legend">
                <div class="name"
                     @click="handleLegend(index)"
                     v-for="(item, index) in list"
                     :key="index">
                    <section :class="currIndex == index ? 'active_gradient' : ''">
                        <img :src="item.src"
                             alt="" />
                        <span>{{ item.name }}</span>
                    </section>
                </div>
            </div>
        </div>
        <div class="box"
             v-if="currType == 'jqzl'">
            <span class="t1">图例：</span>
            <div class="legend"
                 @click="handleLegend(index)"
                 v-for="(item, index) in list"
                 :key="index">
                <img class="icon"
                     :src="`/images/global/ekLegend/bdg/jqzl/lengend-${index + 1}.png`"
                     alt="" />
                <span class="name"
                      :class="currIndex == index ? 'active' : ''">{{
          item.name
        }}</span>
            </div>
        </div>
        <div class="box"
             v-if="currType == 'jqfw'">
            <span class="t1">图例：</span>
            <div class="legend"
                 @click="handleLegend(index)"
                 v-for="(item, index) in list"
                 :key="index">
                <img class="icon"
                     :src="`/images/global/ekLegend/bdg/jqfw/lengend-${index + 1}.png`"
                     alt="" />
                <span class="name"
                      :class="currIndex == index ? 'active' : ''">{{
          item.name
        }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { fetchFindByType } from '@/api'

export default {
    data() {
        return {
            currIndex: 0,
            currType: '',
            list: []
        }
    },
    computed: {
        ...mapGetters(['headerType', 'menuType'])
    },
    mounted() {
        this.watchHeaderType(this.headerType)
    },
    methods: {
        watchHeaderType(type) {
            this.currType = type
            switch (type) {
                case 'ov':
                    this.watchMenuType(this.menuType)
                    break
                case 'iw':
                    this.fetchFindByType('device_type')
                    this.list = [
                        { type: 'dev', name: '设备' },
                        { type: 'tcc', name: '停车场' },
                        { type: 'kljk', name: '客流监控' },
                        { type: 'afjk', name: '安防监控' },
                        { type: 'yyjk', name: '鹰眼监控' },
                        { type: 'wifi', name: 'wifi' },
                        { type: 'ljt', name: '垃圾桶' },
                        { type: 'jg', name: '井盖' },
                        { type: 'zndg', name: '路灯' },
                        { type: 'gb', name: '广播' },
                        // { type: 'ks', name: '扩声' },
                        { type: 'bjz', name: '报警柱' },
                        { type: 'gc', name: '公厕' },
                        { type: 'bz', name: '摆闸' },
                        { type: 'db', name: '单兵' },
                        { type: 'fwss', name: '服务设施' }
                        // { type: 'ssgd', name: '实时工单' }
                    ]
                    break
                case 'cs':
                    this.list = [
                        { type: 'dev', name: '全部' },
                        { type: 'online', name: '线上用户' },
                        { type: 'areaUsers', name: '区域客流' }
                    ]
                    break
                // case 'do':
                //   this.list = [
                //     { type: 'all', name: '全部' },
                //     { type: 'online', name: '用户上线' },
                //     { type: 'trade', name: '用户交易' },
                //     { type: 'ordering', name: '在线点餐' },
                //     { type: 'rate', name: '用户评价' },
                //     { type: 'activity', name: '参与活动' }
                //   ]
                //   break
                case 'bdg':
                    this.watchMenuType(this.menuType)
                    break
                case 'std':
                    this.list = [
                        { type: 'afjk', name: '安防监控' },
                        { type: 'rlrl', name: '人流热力' },
                        { type: 'scdb', name: '手持单兵' }
                    ]
                    this.$eventBus.$off('std-legedAFJK')

                    this.$eventBus.$off('std-r1-iswarning-legend')
                    setTimeout(() => {
                        this.$eventBus.$on('std-r1-iswarning-legend', res => {
                            if (res.flag) {
                                //显示人流热力
                                this.currIndex = 0
                                // this.$eventBus.$emit('std-legend', this.list[this.currIndex])
                            }
                        })
                        this.$eventBus.$on('std-legedAFJK', () => {
                            this.currIndex = 0
                        })
                    }, 300)

                    break
            }
        },
        watchMenuType(type) {
            this.currType = type
            switch (type) {
                case 'br':
                    this.list = [
                        { type: 'all', name: '综合' },
                        { type: 'sy', name: '商业' },
                        { type: 'lzh', name: '老字号' },
                        { type: 'whdkd', name: '网红打卡点' },
                        { type: 'wh', name: '文化' }
                    ]
                    this.$eventBus.$off('ov-br-r3ToLegned')
                    this.$eventBus.$on('ov-br-r3ToLegned', item => {
                        this.list.map((obj, i) => {
                            if (obj.type == item.type) {
                                this.currIndex = i
                            }
                        })
                    })
                    this.$eventBus.$off('ov-br-l2ToLegend')
                    this.$eventBus.$on('ov-br-l2ToLegend', () => {
                        this.list.map((obj, i) => {
                            if (obj.type == 'wh') {
                                this.currIndex = i
                            }
                        })
                    })
                    break
                case 'jqdj':
                    this.list = [
                        {
                            type: 'ljdw',
                            name: '联建单位',
                            src: require('../../../../public/images/map/bdg/jqdj/ljdw.png')
                        },
                        // {
                        //     type: 'shdy',
                        //     name: '商户党员',
                        //     src: require('../../../../public/images/map/bdg/jqdj/shdy.png')
                        // }
                    ]
                    break
                case 'jqzl':
                    this.list = [
                        { type: 'all', name: '全部' },
                        { type: 'emergency_response', name: '应急处置' },
                        { type: 'enterprise_inspect', name: '安全生产' },
                        { type: 'city_management', name: '城市管理' },
                        { type: 'public_service', name: '公共服务' },
                        { type: 'zhzl', name: '综合治理' },
                        { type: 'sj', name: '事件' },
                    ]
                    break
                case 'jqfw':
                    this.list = [
                        { type: 'all', name: '全部' },
                        { type: 'wuxing', name: '五星商户' },
                        { type: 'sixing', name: '四星商户' },
                        { type: 'sanxing', name: '三星商户' },
                        { type: 'erxing', name: '二星商户' },
                        { type: 'yixing', name: '一星商户' }
                    ]
                    break
            }
            console.log(this.currType)
        },
        handleLegend(index) {
            this.currIndex = index
            switch (this.currType) {
                case 'br':
                    this.$eventBus.$emit('ov-br-legend', this.list[index])
                    this.$eventBus.$emit('ov-br-legend-click')
                    break
                case 'bfp':
                    this.$eventBus.$emit('ov-bfp-legend', index)
                    break
                case 'iw':
                    this.$eventBus.$emit('iw-legend', this.list[index])
                    if (this.list[index].type == 'zndg') {
                        this.$eventBus.$emit('ldpop-demo', {
                            data: {
                                bzImg: '/images/components/iw/bz-close.png',
                                dgFlag: '关'
                            }
                        })
                    }

                    break
                case 'cs':
                    this.$eventBus.$emit('cs-legend', this.list[index])
                    break
                // case 'do':
                //   this.$eventBus.$emit('do-legend', this.list[index])
                //   break
                case 'jqdj':
                    this.$eventBus.$emit('bdg-jqdj-legend', this.list[index])
                    break
                case 'std':
                    this.$eventBus.$emit('std-legend', this.list[index])
                    this.$eventBus.$emit('std-legend-map', this.list[index])
                    console.log(this.list[index])
                    break
                case 'jqzl':
                    this.$eventBus.$emit('bdg-jqzl-legend', this.list[index])
                    break
                case 'jqfw':
                    this.$eventBus.$emit('bdg-jqfw-legend', this.list[index])
                    break
            }
        },

        async fetchFindByType(code) {
            const { resultCode, resultData } = await fetchFindByType(code)
            if (resultCode === '200' && resultData) {
                return resultData
            }
        }
    }
}
</script>

<style scoped lang="scss">
.ek-legend {
    @include flex(column);
    position: absolute;
    right: 10px;
    bottom: 10px;
    // width: 185px;
    pointer-events: all;
    // background: #061719;
    // border-image: linear-gradient(-41deg, #ef9c00, #ef9c00, #ef9c00) 10 10;
    // opacity: 0.6;
    .box {
        @include flex(column);
        padding: 20px 0;
    }
    .box_row {
        .legend {
            @include flex();
            font-weight: 600;
            .name {
                section {
                    @include flex(column);
                    align-items: center;
                    > span {
                        width: 88px;
                        height: 34px;
                        margin-top: 24px;
                        line-height: 34px;
                        text-align: center;
                        background-image: url('/images/components/bdg/three.png');
                        background-size: 100% 100%;
                    }
                }
            }
            .active_gradient {
                background: linear-gradient(to top, #ef9c00, #fff);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }
    }
    .t1 {
        font-size: 22px;
        padding: 20px 20px 10px;
    }
    .legend {
        @include flex();
        align-items: center;
        padding: 10px 30px 0 20px;
        cursor: pointer;
        .icon {
            width: 24px;
            height: 22px;
        }
        .name {
            font-size: 18px;
            margin-left: 16px;
        }
        .active {
            color: #ef9c00;
        }
    }
    .dev-state {
        @include flex(column);
        padding: 30px 30px 0 20px;
        // padding: 10px 30px 0 20px;
        &-box {
            @include flex(column);
            padding: 20px 0;
        }
        &-text {
            @include flex();

            span {
                position: relative;
            }
        }
        &-text:first-child > span::after {
            position: absolute;
            content: '';
            right: -35px;
            width: 20px;
            height: 20px;
            background: #00ffff;
            border-radius: 50%;
        }
        &-text:last-child {
            margin-top: 10px;
        }
        &-text:last-child > span::after {
            position: absolute;
            content: '';
            right: -35px;
            width: 20px;
            height: 20px;
            background: #ef003e;
            border-radius: 50%;
        }
    }
}
</style>
