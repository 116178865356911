var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.list.length > 0)?_c('div',{staticClass:"ek-legend",style:({
  background: `url(/images/global/ekLegend/${
    _vm.list.length > 6 ? 'big' : 'small'
  }.png)`,
  'background-size': '100% 100%'
})},[(_vm.currType == 'br' || _vm.currType == 'std')?_c('div',{staticClass:"box"},[_c('span',{staticClass:"t1"},[_vm._v("图例：")]),_vm._l((_vm.list),function(item,index){return _c('div',{key:index,staticClass:"legend",on:{"click":function($event){return _vm.handleLegend(index)}}},[_c('img',{staticClass:"icon",attrs:{"src":`/images/global/ekLegend/ov/br/${
          item.type == 'all'
            ? _vm.currIndex == index
              ? item.type + '-s'
              : item.type + '-u'
            : item.type
        }.png`,"alt":""}}),_c('span',{staticClass:"name",class:_vm.currIndex == index ? 'active' : ''},[_vm._v(_vm._s(item.name))])])})],2):_vm._e(),(_vm.currType == 'bfp')?_c('div',{staticClass:"box"},[_c('span',{staticClass:"t1"},[_vm._v("图例：")]),_vm._l((_vm.list),function(item,index){return _c('div',{key:index,staticClass:"legend",on:{"click":function($event){return _vm.handleLegend(index)}}},[_c('img',{staticClass:"icon",attrs:{"src":`/images/global/ekLegend/${
          _vm.currType == 'bfp' ? '/ov/bfp/' : _vm.currType + '/'
        }${index + 1}-${_vm.currIndex == index ? 's' : 'u'}.png`,"alt":""}}),_c('span',{staticClass:"name",class:_vm.currIndex == index ? 'active' : ''},[_vm._v(_vm._s(item.name))])])})],2):_vm._e(),(_vm.currType == 'iw')?_c('div',{staticClass:"box"},[_c('span',{staticClass:"t1"},[_vm._v("图例：")]),_vm._l((_vm.list),function(item,index){return _c('div',{key:index,staticClass:"legend",on:{"click":function($event){return _vm.handleLegend(index)}}},[_c('img',{staticClass:"icon",attrs:{"src":`/images/global/ekLegend/${
          _vm.currType == 'iw' || _vm.currType == 'cs' ? 'cs' : ''
        }/${item.type}${_vm.currIndex == index ? '-s' : '-u'}.png`,"alt":""}}),_c('span',{staticClass:"name",class:_vm.currIndex == index ? 'active' : ''},[_vm._v(_vm._s(item.name))])])}),_vm._m(0)],2):_vm._e(),(_vm.currType == 'cs')?_c('div',{staticClass:"box"},[_c('span',{staticClass:"t1"},[_vm._v("图例：")]),_vm._l((_vm.list),function(item,index){return _c('div',{key:index,staticClass:"legend",staticStyle:{"padding":"10px 40px 0 20px"},on:{"click":function($event){return _vm.handleLegend(index)}}},[_c('img',{staticClass:"icon",staticStyle:{"width":"28px","height":"33px"},attrs:{"src":`/images/global/ekLegend/cs/${item.type}${
          _vm.currIndex == index ? '-s' : '-u'
        }.png`,"alt":""}}),_c('span',{staticClass:"name",class:_vm.currIndex == index ? 'active' : '',staticStyle:{"font-size":"20px"}},[_vm._v(_vm._s(item.name))])])})],2):_vm._e(),(_vm.currType == 'jqdj')?_c('div',{staticClass:"box_row"},[_c('div',{staticClass:"legend"},_vm._l((_vm.list),function(item,index){return _c('div',{key:index,staticClass:"name",on:{"click":function($event){return _vm.handleLegend(index)}}},[_c('section',{class:_vm.currIndex == index ? 'active_gradient' : ''},[_c('img',{attrs:{"src":item.src,"alt":""}}),_c('span',[_vm._v(_vm._s(item.name))])])])}),0)]):_vm._e(),(_vm.currType == 'jqzl')?_c('div',{staticClass:"box"},[_c('span',{staticClass:"t1"},[_vm._v("图例：")]),_vm._l((_vm.list),function(item,index){return _c('div',{key:index,staticClass:"legend",on:{"click":function($event){return _vm.handleLegend(index)}}},[_c('img',{staticClass:"icon",attrs:{"src":`/images/global/ekLegend/bdg/jqzl/lengend-${index + 1}.png`,"alt":""}}),_c('span',{staticClass:"name",class:_vm.currIndex == index ? 'active' : ''},[_vm._v(_vm._s(item.name))])])})],2):_vm._e(),(_vm.currType == 'jqfw')?_c('div',{staticClass:"box"},[_c('span',{staticClass:"t1"},[_vm._v("图例：")]),_vm._l((_vm.list),function(item,index){return _c('div',{key:index,staticClass:"legend",on:{"click":function($event){return _vm.handleLegend(index)}}},[_c('img',{staticClass:"icon",attrs:{"src":`/images/global/ekLegend/bdg/jqfw/lengend-${index + 1}.png`,"alt":""}}),_c('span',{staticClass:"name",class:_vm.currIndex == index ? 'active' : ''},[_vm._v(_vm._s(item.name))])])})],2):_vm._e()]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dev-state"},[_c('span',[_vm._v("设备状态：")]),_c('div',{staticClass:"dev-state-box"},[_c('div',{staticClass:"dev-state-text"},[_c('span',[_vm._v("在线")])]),_c('div',{staticClass:"dev-state-text"},[_c('span',[_vm._v("异常")])])])])
}]

export { render, staticRenderFns }