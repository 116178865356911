const getters = {
  headerType: state => state.headerType, // headerType类型
  menuType: state => state.menuType, // 二级导航
  cancelTokenArr: state => state.cancelTokenArr,
  dictionaryMap: state => state.dictionaryMap,
  dictionaryList: state => dicType => {
    return state.dictionaryMap[dicType]
  }
}
export default getters
